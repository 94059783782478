@use 'Styles/modules/highlight';

// page styles
h1 {
  color: coral;
}

.pug-logo {
  width: 200px;
}

.image-size {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  height: 100%;
}

.iconfont-image-size {
  &::before {
    font-size: 100px;
  }
}

.icon-bar {
  min-height: 64px;

  >* {
    &:hover {
      transform: scale(1.5);
      margin: 0 10px;

      &+.icon {
        margin: 0;
      }
    }

    transition: all 0.3s;
    transform-origin: center bottom;
  }
}

/* Fil actus list*/
ul.timeline {
  list-style-type: none;
  position: relative;
}

ul.timeline:before {
  content: ' ';
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}

ul.timeline>li {
  margin: 20px 0;
  padding-left: 20px;
}

ul.timeline>li:before {
  content: ' ';
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #cd2147;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
}

/*vertical carousel*/
.vert .carousel-item-next.carousel-item-left,
.vert .carousel-item-prev.carousel-item-right {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.vert .carousel-item-next,
.vert .active.carousel-item-right {
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100% 0);
}

.vert .carousel-item-prev,
.vert .active.carousel-item-left {
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}